import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Games.css";

const GameList = ({ games }) => (
  <table className="table">
    <thead>
      <tr>
        <th />
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      {games.map((game) => {
        return (
          <tr key={game.id}>
            <td>
              <Link to={"/game/" + game.id}>
                {game.title}
                <br></br>
                <img
                  className="game-icon"
                  src={process.env.PUBLIC_URL + "/images/" + game.icon}
                />
              </Link>
              <br></br>
            </td>
            <td style={{ whiteSpace: "pre-wrap" }}>{game.description}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

GameList.propTypes = {
  games: PropTypes.array.isRequired,
};

export default GameList;
