import React from "react";

const AboutPage = () => (
  <div>
    <h2>About</h2>
    <p>
      This website, as well as all featured apps, is a result of my after-work
      hobby when I tried to learn new techniques and tricks of app development.
      Any suggestions for app improvements may or may not be reviewed or
      implemented.
    </p>
    <p>
      Each app serves a single full-screen ad a day. For people with ad blockers
      (around 30% of all Android users), to remove the daily ad, please install
      all apps.
    </p>
  </div>
);

export default AboutPage;
