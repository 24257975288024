import React from "react";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
import "./Games.css";

const GameDetails = ({ game /*, errors */ }) => (
  <>
    <h2>{game.title}</h2>
    <div className="row">
      <div className="col-6">
        <div className="row">
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + "/images/" + game.icon}
          />
        </div>
      </div>
      <div className="col-6 wrap-text" style={{ whiteSpace: "pre-wrap" }}>
        {game.description}
      </div>
    </div>
  </>
);

GameDetails.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameDetails;
