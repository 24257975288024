import * as types from "./actionTypes";
import * as gameApi from "../../api/gameApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadGameSuccess(games) {
  return { type: types.LOAD_GAMES_SUCCESS, games };
}

export function loadGames() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return gameApi
      .getGames()
      .then((games) => {
        dispatch(loadGameSuccess(games));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
