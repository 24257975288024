import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadGames } from "../../redux/actions/gameActions";
import PropTypes from "prop-types";
import GameDetails from "./GameDetails";
import Spinner from "../common/Spinner";
//import { toast } from "react-toastify";

export const GameDetailsPage = ({ games, loadGames, ...props }) => {
  const [game, setGame] = useState({ ...props.game });
  const [errors /*, setErrors*/] = useState({});

  useEffect(() => {
    if (games.length === 0) {
      loadGames().catch((error) => {
        alert("Loading courses failed" + error);
      });
    } else {
      setGame({ ...props.game });
    }
  }, [props.game]);

  return games.length === 0 ? (
    <Spinner />
  ) : (
    <GameDetails game={game} errors={errors} />
  );
};

GameDetailsPage.propTypes = {
  game: PropTypes.object,
  games: PropTypes.array.isRequired,
  loadGames: PropTypes.func.isRequired,
};

export function getGameById(games, id) {
  return games.find((course) => course.id === id) || null;
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id;
  const game = getGameById(state.games, id);
  return {
    game,
    games: state.games,
  };
}

const mapDispatchToProps = {
  loadGames,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameDetailsPage);
