import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import { bindActionCreators } from "redux";
import * as gameActions from "../../redux/actions/gameActions";
import GameList from "./GameList";

const GamesPage = ({ games, loading, actions }) => {
  useEffect(() => {
    if (games.length === 0) {
      actions.loadGames().catch((error) => {
        alert("Loading games failed" + error);
      });
    }
  }, []);

  return (
    <>
      <h2>Games</h2>
      {loading ? <Spinner /> : <GameList games={games} />}
    </>
  );
};

GamesPage.propTypes = {
  games: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    games: state.games.length === 0 ? [] : state.games,
    loading: state.apiCallsInProgress > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      loadGames: bindActionCreators(gameActions.loadGames, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GamesPage);
